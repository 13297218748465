import "./styles.scss"

import React from "react"

const PageHeader = ({ title }) => {
  return (
    <section className="page-header">
      <div className="container-fluid">
        <img
          src={require("assets/images/page-header-slogan.svg").default}
          alt=""
        />
        <h3>{title}</h3>
      </div>
      <img
        className="page-header__image"
        src={require("assets/images/page-header-bg.png").default}
        alt=""
      />
    </section>
  )
}

export default PageHeader
