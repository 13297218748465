import "./styles.scss"

import React from "react"

import Button from "components/Button"

import t from "locale"

const Main = ({ lang }) => {
  return (
    <section className="not-found-main">
      <div className="container-fluid">
        <img src={require("assets/icons/404.svg").default} alt="" />
        <h2>{t("Nie znaleźliśmy strony, której szukasz", lang)}</h2>
        <p>
          {t(
            "Na szczęście wiemy, gdzie możesz znaleźć coś interesującego:",
            lang
          )}
        </p>
        <Button to="/">{t("Strona główna", lang)}</Button>
      </div>
    </section>
  )
}

export default Main
