import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Main } from "page_components/404"

import t from "locale"

const NotFoundPage = ({ location }) => {
  const getLanguage = location.pathname.split("/")[1]
  const lang = getLanguage === "en" ? "en" : "pl"

  return (
    <Layout
      seo={{ title: `404: ${t("Strona nie istnieje", lang)}` }}
      lang={lang}
    >
      <PageHeader title={t("Strona nie istnieje", lang)} />
      <Main lang={lang} />
    </Layout>
  )
}

export default NotFoundPage
